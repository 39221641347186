// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-board-js": () => import("./../../../src/pages/about-us/board.js" /* webpackChunkName: "component---src-pages-about-us-board-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-management-js": () => import("./../../../src/pages/about-us/management.js" /* webpackChunkName: "component---src-pages-about-us-management-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-financial-calendar-js": () => import("./../../../src/pages/investors/financial-calendar.js" /* webpackChunkName: "component---src-pages-investors-financial-calendar-js" */),
  "component---src-pages-investors-general-meetings-js": () => import("./../../../src/pages/investors/general-meetings.js" /* webpackChunkName: "component---src-pages-investors-general-meetings-js" */),
  "component---src-pages-investors-index-js": () => import("./../../../src/pages/investors/index.js" /* webpackChunkName: "component---src-pages-investors-index-js" */),
  "component---src-pages-investors-ir-contacts-js": () => import("./../../../src/pages/investors/ir-contacts.js" /* webpackChunkName: "component---src-pages-investors-ir-contacts-js" */),
  "component---src-pages-investors-media-js": () => import("./../../../src/pages/investors/media.js" /* webpackChunkName: "component---src-pages-investors-media-js" */),
  "component---src-pages-investors-news-js": () => import("./../../../src/pages/investors/news.js" /* webpackChunkName: "component---src-pages-investors-news-js" */),
  "component---src-pages-investors-presentations-js": () => import("./../../../src/pages/investors/presentations.js" /* webpackChunkName: "component---src-pages-investors-presentations-js" */),
  "component---src-pages-investors-results-and-presentations-js": () => import("./../../../src/pages/investors/results-and-presentations.js" /* webpackChunkName: "component---src-pages-investors-results-and-presentations-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

